<template>
  <div>
    <h2>Farewell!!</h2>
    <br>
    <p>
      You have been logged out successfully.
    </p>
  </div>
</template>

<script>
import {Action} from '@/store/session/types'

export default {
  name: 'Logout',
  metaInfo: {
    title: 'Logout',
  },
  mounted() {
    this.$store.dispatch(`${[Action.Logout]}`)
  },
}
</script>
